import React, { useLayoutEffect, useRef } from 'react';
import Bg from '../assets/img/here_for_you_bg.png';
import useBreakpoint from '../utils/useBreakpoint';

const ImageCardContainer = ({
  children,
  backgroundImage,
  nav,
  navTitle,
  title,
  description,
  sign,
  className,
  style,
  handleNavigate,
  rest,
}) => {
  const isMobile = useBreakpoint('sm', false);
  const cardRef = useRef();
  const childrenRef = useRef();

  useLayoutEffect(() => {
    const height = cardRef.current.clientHeight;
    cardRef.current.style.top = isMobile ? '-120px' : -height / 2 + 'px';
    childrenRef.current.style.top = isMobile ? '-120px' : -height / 2 + 'px';
  }, [isMobile]);

  return (
    <>
      <div
        className="image-card-image-background"
        style={{
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : `url(${Bg})`,
          ...style,
        }}
        {...rest}
      />
      <div className="image-card-outer-container">
        <div className="image-card-inner-container" ref={cardRef}>
          <div className="color-text-dark subtitle">
            <span className="blog-nav-link" onClick={() => handleNavigate()}>
              {nav}
            </span>
            <span style={{ color: '#9B9B9B' }}>{' / '}</span>
            {navTitle}
          </div>
          <div className="h3 image-card-title">{title}</div>
          <div className="subtitle image-card-subtitle">{description}</div>
          {sign && <div className="paragraph image-card-sign">{sign}</div>}
        </div>
        <div className={`image-card-children-container ${className}`} ref={childrenRef}>
          {children}
        </div>
      </div>
    </>
  );
};

export default ImageCardContainer;
