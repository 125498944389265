import React from 'react';
import Bg from '../assets/img/privacy-policy-bg.png';
import ImageCardContainer from '../components/ImageCardContainer';
import { RichText } from 'prismic-reactjs';
import Seo from '../components/SEO';
import { navigate } from 'gatsby';

const PrivacyPolicy = ({ pageContext }) => {
  if (!pageContext) {
    return '';
  }

  const pageLang = Object.keys(pageContext)[0];
  const pageData = Object.values(pageContext)[0];
  const homeTitle = Object.values(pageContext)[0]?.homeData?.page_name;

  const handleNavigate = () => {
    if (pageLang === 'en') {
      navigate('/');
      return;
    }
    navigate(`/${pageLang}`);
  };

  return (
    <div
      className="background-color-background"
      style={{
        overflowX: 'hidden',
      }}
    >
      <Seo
        title={pageData?.meta_title}
        description={pageData?.meta_description}
        image={pageData?.meta_image?.url}
        lang={pageLang}
      />
      <ImageCardContainer
        backgroundImage={Bg}
        className="careers-container"
        nav={homeTitle}
        navTitle={pageData?.page_name}
        title={pageData?.title}
        description={pageData?.subtitle}
        handleNavigate={handleNavigate}
        sign={pageData?.author}
      >
        <div className="subtitle" style={{ width: '100%', margin: '80px 0' }}>
          <RichText render={pageData?.content?.raw} />
        </div>
      </ImageCardContainer>
    </div>
  );
};

export default PrivacyPolicy;
